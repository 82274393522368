.container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  gap: 1.18rem;
  height: max-content;
  padding: 0 1.9rem 12rem 1.9rem;
  & :nth-child(1) {
    grid-row: 1/1;
    grid-column: 1/3;
    background: white;
    border-radius: 1.5rem;
  }
  & :nth-child(2) {
    grid-row: 1/2;
    grid-column: 3/5;
    background: white;
    border-radius: 1.5rem;
  }
  & :nth-child(3) {
    grid-row: 2/2;
    grid-column: 1/3;
    background: white;
    border-radius: 1.5rem;
  }
  & :nth-child(4) {
    grid-row: 2/2;
    grid-column: 3/4;
    background: white;
    border-radius: 1.5rem;
  }
  & :nth-child(5) {
    grid-row: 2/2;
    grid-column: 4/5;
    background: white;
    border-radius: 1.5rem;
  }
}

.cardContainer1 {
  display: flex;
  flex-direction: column;
}

.popupContainerRoot {
  display: flex;
  flex-direction: column;
  height: max-content;
  & :nth-child(1) {
    color: #FFF;

    font-family: Artegra Sans Extended SC;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
  }
  & > li {
    color: #EA3323 !important;

    align-self: baseline;
    @media only screen and (max-width: 768px) {
      font-size: 1.875rem;
    }
    font-family: Artegra Sans Extended SC;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  & > span {
    color: #FFF;
    font-family: Artegra Sans Extended SC;
    @media only screen and (max-width: 768px) {
      font-size: 1.875rem !important;
    }
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.popupContainerRoot2 {
  display: flex;
  flex-direction: column;
  height: max-content;
  align-items: baseline !important;
  & > span {
    color: #EA3323;
    font-family: Artegra Sans Extended SC;
    font-size: 1.10513rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  & > li {
    color: #FFF;
    font-family: Artegra Sans Extended SC;
    @media only screen and (max-width: 768px) {
      font-size: 1.875rem;
    }
    font-size: 0.79206rem;
    font-style: normal;
    font-weight: 400;
  }
}
.popupContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
  & :nth-child(1) {
    padding: 1rem;
    width: max-content;
    border-radius: 50%;
    background: red;
    color: #FFF;
    font-family: Artegra Sans Extended SC;
    font-size: 1.94288rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  & :nth-child(2) {
    color: #FFF;

    font-size: 1.875rem;
    font-family: Artegra Sans Extended SC;
    @media only screen and (max-width: 768px) {
      font-size: 1.875rem;
    }
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  & :nth-child(3) {
    color: #FFF;
    font-family: Artegra Sans Extended SC;
    @media only screen and (max-width: 768px) {
      font-size: 1.875rem;
    }
    font-size: 0.82519rem;
    font-style: normal;
    font-weight: 400;
  }
}

.cardContainer1d1 {
  gap: 2.3rem;
  height: min-content;
  display: flex;
  padding-top: 1.57rem;
  padding-left: 1.16rem;
  align-items: center;
  & :nth-child(1) {
    padding: 1rem;
    border-radius: 50%;
    background: red;
    color: #FFF;
    font-family: Artegra Sans Extended SC;
    font-size: 1.94288rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  & :nth-child(2) {
    color: #000;
    font-family: Artegra Sans Extended SC;
    font-size: 1.80331rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.listContainer {
  height: 100%;
  margin-top: auto;
  margin-bottom: 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
