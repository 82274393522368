@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCBoldIt.eot');
    src: local('Artegra Sans Extended SC Bold Italic'), local('ArtegraSansExSCBoldIt'),
        url('ArtegraSansExSCBoldIt.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCBoldIt.woff2') format('woff2'),
        url('ArtegraSansExSCBoldIt.woff') format('woff'),
        url('ArtegraSansExSCBoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCBlackIt.eot');
    src: local('Artegra Sans Extended SC Black Italic'), local('ArtegraSansExSCBlackIt'),
        url('ArtegraSansExSCBlackIt.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCBlackIt.woff2') format('woff2'),
        url('ArtegraSansExSCBlackIt.woff') format('woff'),
        url('ArtegraSansExSCBlackIt.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCEBoldIt.eot');
    src: local('Artegra Sans Extended SC ExtraBold Italic'), local('ArtegraSansExSCEBoldIt'),
        url('ArtegraSansExSCEBoldIt.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCEBoldIt.woff2') format('woff2'),
        url('ArtegraSansExSCEBoldIt.woff') format('woff'),
        url('ArtegraSansExSCEBoldIt.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCBold.eot');
    src: local('Artegra Sans Extended SC Bold'), local('ArtegraSansExSCBold'),
        url('ArtegraSansExSCBold.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCBold.woff2') format('woff2'),
        url('ArtegraSansExSCBold.woff') format('woff'),
        url('ArtegraSansExSCBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCEBold.eot');
    src: local('Artegra Sans Extended SC ExtraBold'), local('ArtegraSansExSCEBold'),
        url('ArtegraSansExSCEBold.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCEBold.woff2') format('woff2'),
        url('ArtegraSansExSCEBold.woff') format('woff'),
        url('ArtegraSansExSCEBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCBlack.eot');
    src: local('Artegra Sans Extended SC Black'), local('ArtegraSansExSCBlack'),
        url('ArtegraSansExSCBlack.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCBlack.woff2') format('woff2'),
        url('ArtegraSansExSCBlack.woff') format('woff'),
        url('ArtegraSansExSCBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCMedIt.eot');
    src: local('Artegra Sans Extended SC Medium Italic'), local('ArtegraSansExSCMedIt'),
        url('ArtegraSansExSCMedIt.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCMedIt.woff2') format('woff2'),
        url('ArtegraSansExSCMedIt.woff') format('woff'),
        url('ArtegraSansExSCMedIt.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCLightIt.eot');
    src: local('Artegra Sans Extended SC Light Italic'), local('ArtegraSansExSCLightIt'),
        url('ArtegraSansExSCLightIt.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCLightIt.woff2') format('woff2'),
        url('ArtegraSansExSCLightIt.woff') format('woff'),
        url('ArtegraSansExSCLightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCThin.eot');
    src: local('Artegra Sans Extended SC Thin'), local('ArtegraSansExSCThin'),
        url('ArtegraSansExSCThin.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCThin.woff2') format('woff2'),
        url('ArtegraSansExSCThin.woff') format('woff'),
        url('ArtegraSansExSCThin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCLight.eot');
    src: local('Artegra Sans Extended SC Light'), local('ArtegraSansExSCLight'),
        url('ArtegraSansExSCLight.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCLight.woff2') format('woff2'),
        url('ArtegraSansExSCLight.woff') format('woff'),
        url('ArtegraSansExSCLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCRegIt.eot');
    src: local('Artegra Sans Extended SC Italic'), local('ArtegraSansExSCRegIt'),
        url('ArtegraSansExSCRegIt.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCRegIt.woff2') format('woff2'),
        url('ArtegraSansExSCRegIt.woff') format('woff'),
        url('ArtegraSansExSCRegIt.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCSBoldIt.eot');
    src: local('Artegra Sans Extended SC SemiBold Italic'), local('ArtegraSansExSCSBoldIt'),
        url('ArtegraSansExSCSBoldIt.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCSBoldIt.woff2') format('woff2'),
        url('ArtegraSansExSCSBoldIt.woff') format('woff'),
        url('ArtegraSansExSCSBoldIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCELgtIt.eot');
    src: local('Artegra Sans Extended SC ExtraLight Italic'), local('ArtegraSansExSCELgtIt'),
        url('ArtegraSansExSCELgtIt.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCELgtIt.woff2') format('woff2'),
        url('ArtegraSansExSCELgtIt.woff') format('woff'),
        url('ArtegraSansExSCELgtIt.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCELight.eot');
    src: local('Artegra Sans Extended SC ExtraLight'), local('ArtegraSansExSCELight'),
        url('ArtegraSansExSCELight.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCELight.woff2') format('woff2'),
        url('ArtegraSansExSCELight.woff') format('woff'),
        url('ArtegraSansExSCELight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCThinIt.eot');
    src: local('Artegra Sans Extended SC Thin Italic'), local('ArtegraSansExSCThinIt'),
        url('ArtegraSansExSCThinIt.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCThinIt.woff2') format('woff2'),
        url('ArtegraSansExSCThinIt.woff') format('woff'),
        url('ArtegraSansExSCThinIt.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCSemiBold.eot');
    src: local('Artegra Sans Extended SC SemiBold'), local('ArtegraSansExSCSemiBold'),
        url('ArtegraSansExSCSemiBold.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCSemiBold.woff2') format('woff2'),
        url('ArtegraSansExSCSemiBold.woff') format('woff'),
        url('ArtegraSansExSCSemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCRegular.eot');
    src: local('Artegra Sans Extended SC'), local('ArtegraSansExSCRegular'),
        url('ArtegraSansExSCRegular.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCRegular.woff2') format('woff2'),
        url('ArtegraSansExSCRegular.woff') format('woff'),
        url('ArtegraSansExSCRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Artegra Sans Extended SC';
    src: url('ArtegraSansExSCMedium.eot');
    src: local('Artegra Sans Extended SC Medium'), local('ArtegraSansExSCMedium'),
        url('ArtegraSansExSCMedium.eot?#iefix') format('embedded-opentype'),
        url('ArtegraSansExSCMedium.woff2') format('woff2'),
        url('ArtegraSansExSCMedium.woff') format('woff'),
        url('ArtegraSansExSCMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

