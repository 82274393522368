.container {
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 4rem;
  }
  margin-top: 3.5rem;
  padding: 0 2.5rem 5rem 4rem;
  justify-content: space-between;
}

.firstContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: initial;
  }
  & :nth-child(1) {
    color: #FFF;
    font-family: Artegra Sans Extended SC;
    font-size: 5.15238rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  & :nth-child(2) {
    color: #3F3F3F;
    font-family: Artegra Sans Extended SC;
    max-width: 80%;
    @media only screen and (max-width: 768px) {
      font-size: 1.5rem;
      max-width: initial;
    }
    font-size: 1.00313rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.secondContainer {
  display: flex;
  flex-direction: column;
  gap: 3.6rem;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  width: 50%;
}

.input {
  background: #0A0A0A;
  height: 3rem;
  outline: 0;
  border-width: 0 0 1px;
  border-color: #3F3F3F;
  color: white;
}

.input::placeholder {
  color: #3F3F3F;
  font-family: Artegra Sans Extended SC;
  font-size: 1.28831rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inputSubmit {
  background: #EA3323;
  border: none;
  border-radius: 10px;
  padding: 1.89rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    margin-bottom: 5rem;
  }
  & > span {
    color: #FFF;
    font-family: Artegra Sans Extended SC;
    position: relative;
    bottom: 0.2em;
    font-size: 1.80331rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8rem;
  }
}
.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.watermark {
  color: #3F3F3F;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
}
