.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.topHide {
  position: absolute;
  background: #0A0A0A;
  width: 100vw;
  height: 50vh;
  z-index: 1;
}

.bottomHide {
  position: absolute;
  background: #0A0A0A;
  width: 100vw;
  height: 50vh;
  bottom: 0;
  z-index: 1;
}
