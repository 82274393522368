.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background: #0A0A0A;
  margin-top: 4rem;
  @media (max-width: 768px) {
    margin-top: 7rem;
  }
  & :first-child {
    -webkit-transform:translateZ(0);
    transform:translateZ(0);
    z-index: 2;
    overflow: hidden;
    width: 100%;
    height: auto;
    bottom: 0.1em;
    position: relative;
  }
  & :nth-child(2) {
    color: #FFF;

    font-family: Artegra Sans Extended SC;
    font-size: 3.925rem;
    text-wrap: nowrap;
    @media (max-width: 768px) {
      font-size: 2.8rem;
    }
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  & :nth-child(3) {
    color: #FFF;
    width: 80%;
    text-align: center;

    margin-top: 2.8rem;
    font-family: Artegra Sans Extended SC;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.svgFill {
  z-index:-1;
  position: absolute;
  content: "";
  background: white;
  width: 97%;
  height: 80%;
  @media (max-width: 768px) {
    bottom: 100%;
    height: 80%;
  }
  bottom: 100%;
  left: 10px;
}

.animateType2 {
  position:relative;
  width: 100vw;
  height:300px;
  margin:auto;
  overflow:hidden;
  @media (min-width: 768px) {
    display: none;
  }
}

.animateType2 h3 {
  position:absolute !important;
  font-weight:700 !important;
  width: 200px !important;
  margin:0 !important;
  padding:0 !important;
  text-align:center !important;
  visibility:hidden;
  font-size: 4rem !important;
  right: 28%;
  top:65px !important;
}
