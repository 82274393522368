.container {
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
  gap: 6.5rem;
  align-items: center;
  justify-content: space-between;
  padding-left: 3.7rem;
  padding-right: 3.7rem;
  margin-bottom: 2.6rem;
  margin-top: 1.8rem;
  & :nth-child(1) {
    color: #FFF;
    font-family: Artegra Sans Extended SC;
    font-size: 5.15238rem;
    font-style: normal;
    font-weight: 800;
    line-height: 4rem;
    height: 4rem;
    position: relative;
    bottom: 0.2em;
    line-height: 5.15rem;
    height: 5.15rem;
  }
  & :nth-child(2) {
    color: #FFF;
    font-family: Artegra Sans Extended SC;
    font-size: 1.34425rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
