.container {
  padding-left: 3.7rem;
  & > span {
    color: #FFF;
    font-family: Artegra Sans Extended SC;
    font-size: 5.15238rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.each-slide-effect {
  & > div { 
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
}

.each-slide-effect {
  & > span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
}
