.container {
  background: #0A0A0A;
  font-size: 2.4rem;
  font-weight: 400;
  padding: 5.12rem 10% 0 13.8rem;
  @media (max-width: 768px) {
    padding: 5.12rem 2rem 0 2rem;
  }
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  & > span {
    color: #FFF;
    font-family: Artegra Sans Extended SC;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1.13rem;
    margin-top: auto;
  }
}

.headerContainer {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  & > span {
    cursor: pointer;
  }
  & > span:hover {
    color: red;
  }
}
@media only screen and (min-width: 768px) {
  .mainLogo {
    position: absolute;
    left: 3%;
    top: 2rem;
  }
}
@media only screen and (max-width: 768px) {
  .mainLogo {
    position: absolute;
    left: 3%;
    top: 1rem;
    width: 20%;
  }
}
@media only screen and (max-width: 1369px) {
  .headerContainer {
    display: none;
  }
}
