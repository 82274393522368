.container{
  background: linear-gradient(90deg, #F55801 0%, #F13201 45.38%, #EC0000 100%);
  color: #FFF;
  padding: 1rem 0;
  font-family: Artegra Sans Extended SC;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  margin-top: auto;
  @media (max-width: 768px) {
    margin-bottom: 15rem;
  }
  margin-bottom: 3rem;
}
