.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background: #0A0A0A;
  @media only screen and (min-width: 768px) {
    margin-top: 10rem;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 1.3rem;
  }
}
.first {
    display: flex;
    gap: 1rem;
    align-items: center;
    & :nth-child(1) {
      font-size: 3rem;
      font-style: italic;
      color: white;
    }
    & :nth-child(2) {
      font-size: 5rem;
      color: #EA3323;
      font-weight: bold;
      text-transform: uppercase;
    }
}
.second {
    font-size: 3.7rem;
    text-transform: uppercase;
  }
.third{
    font-size: 1.3rem;
    @media only screen and (min-width: 768px) {
      max-width: 30%;
    }
    @media only screen and (max-width: 768px) {
      max-width: 40%;
    }
    align-self: flex-end;
    text-transform: uppercase;
    padding-right: 2rem;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
.fouth {
  position: relative;
  align-self: end;
  @media only screen and (min-width: 768px) {
    margin-top: 8rem;
    margin-right: 35%;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 2rem;
    margin-right: 85%;
  }
  & :nth-child(1) {
    font-size: 1.3rem;
    @media only screen and (max-width: 768px) {
      max-width: 30%;
    }
    color: white;
    text-transform: uppercase;
  }
  & :nth-child(2) {
    position: absolute;
    @media only screen and (max-width: 768px) {
      width: 200%;
    }
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.fifth {
  position: relative;
  margin-top: 8rem;
  align-self: flex-start;
  margin-left: 15%;
  margin-right: auto;
  & :nth-child(1) {
    font-size: 1.3rem;
    color: white;
    max-width: 30%;
    text-transform: uppercase;
  }
  & :nth-child(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    transform: translate(-50%, -50%);
  }
}
.sixth {
  position: relative;
  margin-top: 8rem;
  align-self: flex-end;
  margin-right: auto;
  & :nth-child(1) {
    text-wrap: nowrap;
    font-size: 1.3rem;
    color: white;
    max-width: 30%;
    text-transform: uppercase;
  }
  & :nth-child(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    transform: translate(-50%, -50%);
  }
}
.seventh {
  @media only screen and (max-width: 768px) {
    margin-top: 15rem;
  }
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  display: inline-block;
  margin-right: auto;
  margin-top: 1.9rem;
  margin-left: 3rem;
  font-size: 1.5rem;
  max-width: 40%;
  align-self: flex-start;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
.eigth{
  font-size: 2rem;
  font-weight: bolder;
  @media only screen and (max-width: 768px) {
    font-size: 1.5rem;
    max-width: 80%;
  }
  
  margin-top: 8.5rem;
  max-width: 60%;
}
.ninth{
  font-size: 3.4rem;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-top: 2.2rem;
  gap: 1rem;
  & :nth-child(1) {
    z-index: 2;
    text-transform: uppercase;
  }
  position: relative;
  & :nth-child(2) {
    z-index: 2;
    color: red;
    font-style: italic;
  }
  & :nth-child(3) {
    position: absolute;
    overflow: visible;
    left: -3%;
    top: 55%;
    transform: translate(0, -50%);
    height: 6rem;
    z-index: 1;
  }
}
.tenth{
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 6rem;
  & :nth-child(1) {
    left: 50%;
    width: 100%;
    height: 100%;
    @media only screen and (max-width: 768px) {
      height: calc(100% - 50vh) !important;
      top: 10% !important;
      transform: translate(-50%,-15%) !important;
    }
    top: 50%;
    object-fit: contain;
    transform: translate(-50%,-50%);
    position: absolute;
    z-index: 2;
  }
  & :nth-child(2) {
    z-index: 1;
    width: auto;
    @media only screen and (max-width: 768px) {
      height: calc(100% - 50vh) !important;
      top: 10% !important;
      transform: translate(-50%,-15%) !important;
    }
    height: 100%;
    overflow: visible;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
  }
  & :nth-child(3) {
    margin-left: 6rem;
    margin-top: 30rem;
    max-width: 30%;
    @media only screen and (max-width: 768px) {
      font-size: 1.8rem !important;
      align-self: center !important;
      max-width: initial !important;
      margin-left: 0 !important;
      padding: 0 5rem !important;
      text-align: justify !important;
      margin-top: 70rem !important;
    }
    align-self: flex-start;
    font-size: 1.3rem;
    z-index: 2;
    text-transform: initial;
  }
  & :nth-child(4) {
    margin-left: 6rem;
    margin-top: 0.5rem;
    margin-right: 6rem;
    max-width: 30%;
    font-size: 1.3rem;
    @media only screen and (max-width: 768px) {
      font-size: 1.8rem;
      align-self: center;
      max-width: initial;
      margin-left: 0;
      padding: 0 5rem;
      text-align: justify;
    }
    align-self: flex-end;
    z-index: 2;
  }
  & :nth-child(5) {
    @media only screen and (max-width: 768px) {
      font-size: 1.8rem;
      align-self: center;
      max-width: initial;
      margin-top: 0;
      margin-left: 0;
      padding: 0 5rem;
      text-align: justify;
    }
    margin-left: 6rem;
    margin-top: 6rem;
    max-width: 30%;
    font-size: 1.3rem;
    align-self: flex-start;
    z-index: 2;
  }
}
.eleventh{
  background: white;
  font-size: 1.9375rem;
  color: #0A0A0A;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 90%;
  & :nth-child(1) {
    align-self: center;
    color: #EA3323;
    font-family: Artegra Sans Extended SC;
    font-size: 4.99319rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  & :nth-child(2) {
    width: max-content;
    position: relative;
    & :nth-child(1) {
    @media only screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
    font-size: 1.9rem;
    font-weight: 600;
    text-transform: initial;
    color: white;
    position: relative;
    z-index: 2;
    }
    & :nth-child(2) {
      background: linear-gradient(90deg, #E30613 0%, #000 100%);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  & :nth-child(3) {
    @media only screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
    text-transform: initial;
    align-self: initial;
    font-size: 1.9rem;
    font-weight: 600;
    max-width: initial;
  }
  & :nth-child(4) {
    @media only screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
    text-transform: initial;
    align-self: initial;
    font-size: 1.9rem;
    font-weight: 600;
    margin: initial;
  }
  & :nth-child(5) {
    @media only screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
    text-transform: initial;
    font-size: 1.9rem;
    font-weight: 600;
    margin: initial;
    align-self: initial;
    width: initial;
  }
  & :nth-child(6) {
    @media only screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
    text-transform: initial;
    font-size: 1.9rem;
    font-weight: 600;
    margin: initial;
    align-self: initial;
  }
  & :nth-child(7) {
    @media only screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
    text-transform: initial;
    font-size: 1.9rem;
    font-weight: 600;
    margin: initial;
    align-self: initial;
    max-width: initial;
  }
  & :nth-child(8) {
    @media only screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
    text-transform: initial;
    font-size: 1.9rem;
    font-weight: 600;
    margin: initial;
    align-self: initial;
    max-width: initial;
    font-weight: normal;
  }
}

.dbackground {
  background: url("./assets/dbackground.svg");
}
